import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'
import Swing from 'react-reveal/Swing'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import classNames from 'classnames'
import Img from 'gatsby-image'

import { useWindowSize } from '_hooks'
import DownloadAppStoreImage from '_images/download-appstore.png'
import LaunchWebAppImage from '_images/launch-webapp.png'
import { AppContext } from '_context'
import { Layout } from '_templates'
import { SEO, ViewportObserver } from '_organisms'
import { Container, Image, Text, ScrollArrow, Heading } from '_atoms'
import { CarouselSlider, ProudCases } from '_molecules'
import FindHeliLogo from '_images/svgs/findheli-logo.inline.svg'
import variables from '_config/css-variables'

import styles from './styles.module.css'

const getSliderOptions = navContainer => {
  return {
    controls: false,
    mouseDrag: true,
    loop: false,
    items: 1.6,
    gap: 0,
    navContainer,
    gutter: 40,
    responsive: {
      480: {
        items: 2,
        gutter: 40,
      },
      768: {
        items: 3,
        gutter: 60,
      },
      1280: {
        gutter: 120,
      },
    },
  }
}

const bookingExperienceSliderOptions = {
  controls: true,
  controlsText: ['<', '>'],
  rewind: true,
  mouseDrag: true,
  loop: false,
  items: 1,
  gap: 0,
  navContainer: '#booking-experience-nav',
  gutter: 0,
}

const FindHeliCaseStudy = ({ data }) => {
  const findHeliCaseStudy = data.contentfulFindHeliCaseStudy

  const { setNavbarColor, setNavbarLight } = useContext(AppContext)

  const [animateHeli, setAnimateHeli] = useState(false)
  const getDistance = elem => elem.getBoundingClientRect()

  const [width] = useWindowSize()
  const isColumnLayout = width < 992

  const [y, setY] = useState(0)

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    setNavbarColor(variables['findheli-blue-primary'])
    setNavbarLight(false)
  }, [setNavbarColor, setNavbarLight])

  const id = 'heli'

  useEffect(() => {
    const heli = document.getElementById(id)

    const distance = getDistance(heli)

    const isInViewport = ({ top, bottom }) => {
      return isColumnLayout ? top <= 300 && bottom >= 450 : top <= 200 && bottom >= 400
    }

    return isInViewport(distance) ? setAnimateHeli(true) : setAnimateHeli(false)
  }, [id, y, width, isColumnLayout])

  return (
    <Layout navbarColorOverride={variables['findheli-blue-primary']}>
      <SEO
        title={findHeliCaseStudy.pageTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={
          findHeliCaseStudy.pageDescription || 'Web &amp; Mobile App Design and Development Company'
        }
        thumbnail={findHeliCaseStudy.pageThumbnail.file.url}
      />

      <section className={styles.hero}>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Fade distance="20" bottom>
                <FindHeliLogo className={styles.findHeliLogo} />
              </Fade>

              <Fade distance="20" delay={200} bottom>
                <Text size="28" className={styles.heroDescription} splitParagraphs>
                  {findHeliCaseStudy.heroDescription.internal.content}
                </Text>
              </Fade>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6}>
              <div className={styles.heroImageWrapper}>
                <Fade distance="20" delay={350} bottom>
                  <Img
                    fluid={findHeliCaseStudy.heroImage.fluid}
                    alt={findHeliCaseStudy.heroImage.description}
                    imgStyle={{ position: 'relative' }}
                    fadeIn={false}
                  />
                </Fade>
              </div>
            </Col>
          </Row>
          <ScrollArrow className={styles.arrowButton} />
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['findheli-blue-primary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.building}>
        <Container>
          <Row className={styles.buildingSectionRow}>
            <Col xs={12} sm={12} md={7} lg={7}>
              <Fade distance="10%" bottom>
                <Heading
                  type="h1"
                  color="white"
                  bold
                  className={classNames(styles.heading, styles.buildingHeading)}
                >
                  {findHeliCaseStudy.buildingTitle}
                  <Text color="findHeliYellow" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>

              <Fade distance="10%" delay={200} bottom>
                <Text
                  color="white"
                  className={classNames(styles.description, styles.buildingDescription)}
                  splitParagraphs
                >
                  {findHeliCaseStudy.buildingDescription.internal.content}
                </Text>
              </Fade>
            </Col>
            <Col xs={12} sm={12} md={5} lg={5}>
              <Fade distance="10%" delay={350} bottom>
                <div className={styles.buildingImagesWrapper}>
                  {findHeliCaseStudy.buildingImages.map(image => (
                    <Image {...image.fixed} key={image.fixed.src} alt={image.description} />
                  ))}
                </div>
              </Fade>
            </Col>
          </Row>

          <ViewportObserver
            scroll={y}
            onViewport={() => {
              setNavbarColor(variables['findheli-blue-accent'])
              setNavbarLight(false)
            }}
          />
        </Container>
      </section>

      <section className={styles.userExperience}>
        <Container>
          <Row className={styles.userExperienceSectionRow}>
            <Col xs={12} sm={12} md={9} lg={9}>
              <Fade distance="10%" bottom>
                <Heading
                  type="h4"
                  color="white"
                  bold
                  className={classNames(styles.heading, styles.userExperienceHeading)}
                >
                  {findHeliCaseStudy.userExperienceTitle}
                  <Text color="findHeliYellow" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>

              <Fade distance="10%" delay={200} bottom>
                <Text
                  color="white"
                  className={classNames(styles.description, styles.userExperienceDescription)}
                  splitParagraphs
                >
                  {findHeliCaseStudy.userExperienceDescription.internal.content}
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>

        <Fade distance="10%" delay={350} bottom>
          <CarouselSlider
            className={styles.slider}
            id="user-experience"
            images={findHeliCaseStudy.userExperienceImages}
            options={getSliderOptions('#user-experience-nav')}
          />
        </Fade>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['findheli-blue-secondary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.visualDesign}>
        <Container>
          <Fade distance="10%" bottom>
            <Heading
              type="h4"
              bold
              className={classNames(styles.heading, styles.visualDesignHeading)}
            >
              {findHeliCaseStudy.visualDesignTitle}
              <Text color="findHeliYellow" className={styles.dot}>
                .
              </Text>
            </Heading>
          </Fade>

          <Fade distance="10%" delay={200} bottom>
            <Text
              color="dark"
              className={classNames(styles.description, styles.visualDesignDescription)}
              splitParagraphs
            >
              {findHeliCaseStudy.visualDesignDescription.internal.content}
            </Text>
          </Fade>
        </Container>

        <Fade distance="10%" delay={350} bottom>
          <CarouselSlider
            className={styles.slider}
            id="visual-design"
            images={findHeliCaseStudy.visualDesignImages}
            options={getSliderOptions('#visual-design-nav')}
          />
        </Fade>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['findheli-blue-light'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.iconSet}>
        <Container>
          <Row className={styles.iconSetContentWrapper}>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Fade distance="10%" delay={300} bottom>
                <Heading
                  type="h4"
                  color="white"
                  bold
                  className={classNames(styles.heading, styles.iconSetHeading)}
                >
                  {findHeliCaseStudy.iconSetTitle}
                  <Text color="findHeliYellow" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>

              <Fade distance="10%" delay={400} bottom>
                <Text
                  className={classNames(styles.description, styles.iconSetDescription)}
                  color="white"
                  splitParagraphs
                >
                  {findHeliCaseStudy.iconSetDescription.internal.content}
                </Text>
              </Fade>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} className={styles.iconSetImageWrapper} id="heli">
              <Fade left delay={isColumnLayout ? 400 : 0}>
                <Swing when={animateHeli}>
                  <Image
                    src={findHeliCaseStudy.iconSetImage.file.url}
                    alt={findHeliCaseStudy.iconSetImage.description}
                  />
                </Swing>
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['findheli-blue-primary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.responsiveLayout}>
        <Container>
          <Row className={styles.responsiveLayoutSectionRow}>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Fade distance="10%" bottom>
                <Heading
                  type="h4"
                  color="white"
                  bold
                  className={classNames(styles.heading, styles.responsiveLayoutHeading)}
                >
                  {findHeliCaseStudy.responsiveLayoutTitle}
                  <Text color="findHeliYellow" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>

              <Fade distance="10%" delay={200} bottom>
                <Text
                  color="white"
                  className={classNames(styles.description, styles.responsiveLayoutDescription)}
                  splitParagraphs
                >
                  {findHeliCaseStudy.responsiveLayoutDescription.internal.content}
                </Text>
              </Fade>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6}>
              <Fade distance="10%" delay={350} bottom>
                <div className={styles.responsiveLayoutImagesWrapper}>
                  {findHeliCaseStudy.responsiveLayoutImages.map(image => (
                    <Image {...image.fixed} key={image.fixed.src} alt={image.description} />
                  ))}
                </div>
              </Fade>
            </Col>
          </Row>

          <ViewportObserver
            scroll={y}
            onViewport={() => {
              setNavbarColor(variables['findheli-blue-accent'])
              setNavbarLight(false)
            }}
          />
        </Container>
      </section>

      <section className={styles.bookingExperience}>
        <Container>
          <Row className={styles.bookingExperienceSectionRow}>
            <Col xs={12} sm={5} md={4} lg={4} className={styles.bookingExperienceSliderWrapper}>
              <div className={styles.bookingExperienceSliderPosition}>
                <Fade distance="10%" bottom>
                  <CarouselSlider
                    className={styles.bookingExperienceSlider}
                    id="booking-experience"
                    images={findHeliCaseStudy.bookingExperienceImages}
                    options={bookingExperienceSliderOptions}
                  />
                </Fade>
              </div>
            </Col>
            <Col xs={12} sm={7} md={8} lg={8} className={styles.bookingExperienceContent}>
              <Fade distance="10%" delay={200} bottom>
                <Heading
                  type="h4"
                  color="white"
                  bold
                  className={classNames(styles.heading, styles.bookingExperienceHeading)}
                >
                  {findHeliCaseStudy.bookingExperienceTitle}
                  <Text color="findHeliBluePrimary" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>

              <Fade distance="10%" delay={350} bottom>
                <Text
                  color="dark"
                  className={classNames(styles.description, styles.bookingExperienceDescription)}
                  splitParagraphs
                >
                  {findHeliCaseStudy.bookingExperienceDescription.internal.content}
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>

        <div className={styles.bookingExperienceStripesBg} />

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['findheli-yellow-primary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.payment}>
        <Container>
          <Row className={styles.paymentSectionRow}>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Fade distance="10%" bottom>
                <Heading
                  type="h4"
                  color="white"
                  bold
                  className={classNames(styles.heading, styles.paymentHeading)}
                >
                  {findHeliCaseStudy.paymentTitle}
                  <Text color="findHeliYellow" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>

              <Fade distance="10%" delay={200} bottom>
                <Text
                  color="white"
                  className={classNames(styles.description, styles.paymentDescription)}
                  splitParagraphs
                >
                  {findHeliCaseStudy.paymentDescription.internal.content}
                </Text>
              </Fade>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.paymentImageWrapper}>
              <Fade distance="10%" delay={350} bottom>
                <Image
                  src={findHeliCaseStudy.paymentImage.file.url}
                  alt={findHeliCaseStudy.paymentImage.description}
                  className={styles.paymentImage}
                />
              </Fade>
            </Col>
          </Row>

          <ViewportObserver
            scroll={y}
            onViewport={() => {
              setNavbarColor(variables['findheli-blue-accent'])
              setNavbarLight(false)
            }}
          />
        </Container>
      </section>

      <section className={styles.controlPanel}>
        <Container>
          <Row className={styles.controlPanelSectionRow}>
            <Fade distance="10%" bottom>
              <Heading
                type="h4"
                color="findHeliBluePrimary"
                bold
                className={classNames(styles.heading, styles.controlPanelHeading)}
              >
                {findHeliCaseStudy.controlPanelTitle}
                <Text color="findHeliYellow" className={styles.dot}>
                  .
                </Text>
              </Heading>
            </Fade>

            <Fade distance="10%" delay={200} bottom>
              <Text
                color="findHeliBluePrimary"
                className={classNames(styles.description, styles.controlPanelDescription)}
                splitParagraphs
              >
                {findHeliCaseStudy.controlPanelDescription.internal.content}
              </Text>
            </Fade>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['findheli-blue-light'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.locationBased}>
        <Container>
          <Row className={styles.locationBasedSectionRow}>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Fade distance="10%" bottom>
                <Heading
                  type="h4"
                  color="white"
                  bold
                  className={classNames(styles.heading, styles.locationBasedHeading)}
                >
                  {findHeliCaseStudy.locationBasedTitle}
                  <Text color="findHeliYellow" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>

              <Fade distance="10%" delay={200} bottom>
                <Text
                  color="white"
                  className={classNames(styles.description, styles.locationBasedDescription)}
                  splitParagraphs
                >
                  {findHeliCaseStudy.locationBasedDescription.internal.content}
                </Text>
              </Fade>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6}>
              <Fade distance="10%" delay={350} bottom>
                <div className={styles.locationBasedImagesWrapper}>
                  {findHeliCaseStudy.locationBasedImages.map(image => (
                    <Image
                      {...image.fixed}
                      key={image.fixed.src}
                      alt={image.description}
                      className={styles.locationBasedImagesWrapperImage}
                    />
                  ))}
                </div>
              </Fade>
            </Col>
          </Row>

          <ViewportObserver
            scroll={y}
            onViewport={() => {
              setNavbarColor(variables['findheli-blue-secondary'])
              setNavbarLight(false)
            }}
          />
        </Container>
      </section>

      <section className={styles.download}>
        <Container>
          <Row className={styles.downloadSectionRow}>
            <Col xs={12} sm={12} md={4} lg={4} className={styles.downloadDescriptionWrapper}>
              <Fade distance="10%" bottom>
                <Text
                  color="white"
                  className={classNames(styles.description, styles.downloadDescription)}
                  splitParagraphs
                >
                  {findHeliCaseStudy.downloadDescription.internal.content}
                </Text>
              </Fade>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              className={classNames(
                styles.downloadImageWrapperDesktop,
                styles.downloadImageWrapper
              )}
            >
              <Fade distance="10%" delay={200} bottom>
                <Image
                  {...findHeliCaseStudy.downloadImage.fixed}
                  key={findHeliCaseStudy.downloadImage.fixed.src}
                  alt={findHeliCaseStudy.downloadImage.description}
                  className={styles.downloadImageWrapperImage}
                />
              </Fade>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className={styles.downloadButtonsWrapper}>
              <Fade distance="10%" delay={300} bottom>
                <a href={findHeliCaseStudy.downloadAppStoreUrl}>
                  <Image src={DownloadAppStoreImage} alt="Download on the App Store" />
                </a>
              </Fade>

              <Fade distance="10%" delay={400} bottom>
                <a href={findHeliCaseStudy.downloadWebAppUrl}>
                  <Image src={LaunchWebAppImage} alt="Launch now as Web App" />
                </a>
              </Fade>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              className={classNames(styles.downloadImageWrapperMobile, styles.downloadImageWrapper)}
            >
              <Image
                {...findHeliCaseStudy.downloadImage.fixed}
                key={findHeliCaseStudy.downloadImage.fixed.src}
                alt={findHeliCaseStudy.downloadImage.description}
                className={styles.downloadImageWrapperImage}
              />
            </Col>
          </Row>

          <ViewportObserver
            scroll={y}
            onViewport={() => {
              setNavbarColor(variables['findheli-blue-accent'])
              setNavbarLight(false)
            }}
          />
        </Container>
      </section>

      <section className={styles.portfolio}>
        <Fade distance="10%" bottom>
          <ProudCases
            currentProject="FindHeli"
            caseStudies={findHeliCaseStudy.proudCases}
            extendedCTA
          />
        </Fade>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query contentfulFindHeliCaseStudy($locale: String) {
    contentfulFindHeliCaseStudy(fields: { localeKey: { eq: $locale } }) {
      pageTitle
      pageDescription
      pageThumbnail {
        file {
          url
        }
      }
      heroImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroDescription {
        internal {
          content
        }
      }
      buildingTitle
      buildingImages {
        description
        fixed {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      buildingDescription {
        internal {
          content
        }
      }
      userExperienceTitle
      userExperienceImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      userExperienceDescription {
        internal {
          content
        }
      }
      visualDesignTitle
      visualDesignImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      visualDesignDescription {
        internal {
          content
        }
      }
      iconSetTitle
      iconSetImage {
        description
        file {
          url
        }
      }
      iconSetDescription {
        internal {
          content
        }
      }
      responsiveLayoutTitle
      responsiveLayoutImages {
        description
        fixed {
          src
          srcSet
        }
      }
      responsiveLayoutDescription {
        internal {
          content
        }
      }
      bookingExperienceTitle
      bookingExperienceImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      bookingExperienceDescription {
        internal {
          content
        }
      }
      paymentTitle
      paymentImage {
        description
        file {
          url
        }
      }
      paymentDescription {
        internal {
          content
        }
      }
      controlPanelTitle
      controlPanelDescription {
        internal {
          content
        }
      }
      locationBasedTitle
      locationBasedImages {
        description
        fixed {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      locationBasedDescription {
        internal {
          content
        }
      }
      downloadImage {
        description
        fixed {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      downloadDescription {
        internal {
          content
        }
      }
      downloadAppStoreUrl
      downloadWebAppUrl
      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

FindHeliCaseStudy.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default FindHeliCaseStudy
